import React, { useEffect, useMemo, useState } from "react";
import { FaUsers } from "react-icons/fa";
import { Link } from "react-router-dom";

import Button from "../../components/Button";
import HeaderContainer from "../../components/HeaderContainer";
import LoadingScreen from "../../components/LoadingScreen";
import PageContainer from "../../components/PageContainer";
import PageTitle from "../../components/PageTitle";
import Paper from "../../components/Paper";
import TableBig from "../../components/TableBig";
import { useAuth } from "../../contexts/AuthContext";
import api from "../../services/api";

import { EditIcon, NewClientLink, StatusContainer } from "./styles";

interface DataProps {
  id: string;
  name: string;
  access: string;
  area: string;
  stats: string;
  edit: React.ReactNode;
}

const AccessControl: React.FC = () => {
  const { user } = useAuth();

  const [users, setUsers] = useState<any>([]);

  const [data, setData] = useState<DataProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const allowEdit = useMemo(() => {
    if (!user) return false;
    if (Object.keys(user).length === 0) return false;
    if (/master/i.test(user.profile_type)) return true;
    return user.permissions.includes("edit_access_control");
  }, [user]);

  useEffect(() => {
    async function loadUsers() {
      const response = await api.get("users/list");

      setUsers(response.data.users);
    }

    loadUsers();
  }, []);

  useEffect(() => {
    async function loadData() {
      const reducer = (
        previousValue: any,
        currentValue: any,
        i: number
      ): string =>
        i !== 0
          ? previousValue + ", " + currentValue.nameRegion
          : currentValue.nameRegion;

      const displayUsersList = users.map((user: any) => ({
        id: user.id,
        name: user.firstName + " " + user.lastName,
        access: user.profileName,
        area:
          user.hasAllRegions === "1"
            ? "Todos"
            : user.region.reduce(reducer, ""),
        stats: user.ativo === "1" ? "Ativo" : "Inativo",
        registration: user.registration || "",
        editUser: (
          <Link
            to={{
              pathname: `/access/user/${user.id}`,
              state: { isEditUserPage: true },
            }}
          >
            <EditIcon>Edit</EditIcon>
          </Link>
        ),
      }));

      setData(displayUsersList);

      setLoading(false);
    }

    if (users && users.length > 0) {
      loadData();
    }
  }, [users]);

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Nome",
        accessor: "name",
      },
      {
        Header: "Matrícula",
        accessor: "registration",
      },
      {
        Header: "Acessos",
        accessor: "access",
      },
      {
        Header: "Área de atuação",
        accessor: "area",
      },
      {
        Header: "Status",
        accessor: "stats",
        Cell: ({ value }: any) => (
          <StatusContainer isActive={value === "Ativo"}>
            {value}
          </StatusContainer>
        ),
      },
    ];

    if (allowEdit) {
      defaultColumns.push({
        Header: "Editar",
        accessor: "editUser",
      });
    }

    return defaultColumns;
  }, [allowEdit]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <PageContainer>
      <HeaderContainer>
        <PageTitle icon={<FaUsers />} title="Controle de acesso" />
      </HeaderContainer>
      <Paper>
        <TableBig
          data={data}
          columns={columns}
          buttons={
            <>
              {allowEdit && (
                <NewClientLink
                  to={{
                    pathname: "/access/user/new",
                    state: { isEditUserPage: false },
                  }}
                >
                  <Button>Adicionar Usuário</Button>
                </NewClientLink>
              )}
            </>
          }
        />
      </Paper>
    </PageContainer>
  );
};

export default AccessControl;
