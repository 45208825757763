import { Checkbox as MuiCheckbox } from "@material-ui/core";
import styled from "styled-components";

export const Header = styled.h2`
  font-size: 14px;
  color: #003b74;
  font-weight: 500;
`;

export const LevelsRow = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem 0 0 0;
`;

interface ProposalItemProps {
  active: boolean;
}

export const LevelButton = styled.li<ProposalItemProps>`
  height: 45px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  list-style: none;

  padding: 15px;
  margin-bottom: 1.5rem;
  cursor: pointer;
  background: ${({ active }) => active && "#003b74"};
  color: ${({ active }) => active && "#FFF"};
  border: ${({ active }) => !active && "1px solid #ddd7e5"};
  border-radius: 12px;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem 1.5rem;
`;

export const ContainerLevel = styled.div`
  width: 100%;
`;

interface ContainerInputsPropos {
  hidden: boolean;
}

export const Inputs = styled.div<ContainerInputsPropos>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  visibility: ${({ hidden }) => hidden && "hidden"};
`;

export const InputsTop = styled.div`
  width: 100%;

  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
`;

export const InputsMiddle = styled.div`
  width: 100%;
  margin-top: 1rem;

  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
`;

export const InputsBottom = styled.div`
  width: 100%;
  margin-top: 1rem;

  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  cursor: pointer;
  user-select: none;
`;

// 1px solid #ddd7e5
export const Checkbox = styled(MuiCheckbox)`
  padding-top: 0.75rem;

  &.Mui-disabled {
    color: #c8c9e0 !important;
  }
  &.MuiCheckbox-colorPrimary.Mui-checked {
    color: #001eba;
  }
  &.MuiCheckbox-root {
    color: rgba(0, 0, 0, 0.25);
  }
`;

export const CheckboxLabel = styled.p`
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.87);
`;
