import React, { useCallback, useEffect } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import { useHistory } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";
// import { useToast } from "../../contexts/ToastContext";

import { Container } from "./styles";

interface SignInFormData {
  email: string;
}

const Login: React.FC = () => {
  // const { instance } = useMsal();

  const { user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!user) {
      const baseURL = process.env.REACT_APP_API_URL ?? "";
      window.location.href = `${baseURL}auth/login`;
    } else {
      history.push("/dashboard");
    }
  }, [user, history]);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      history.push("/dashboard");

      // try {
      //   formRef.current?.setErrors({});

      //   const schema = Yup.object().shape({
      //     email: Yup.string()
      //       .required("E-mail obrigatótio")
      //       .email("Digite um e-mail valido"),
      //   });

      //   await schema.validate(data, {
      //     abortEarly: false,
      //   });

      //   // await signIn({
      //   //   email: data.email
      //   // });

      //   history.push("/dashboard");
      // } catch (err) {
      //   if (err instanceof Yup.ValidationError) {
      //     const errors = getValidationErrors(err);
      //     // let description;

      //     // if (errors.email && errors.password) {
      //     //   description = errors.email + " e " + errors.password;
      //     // } else if (errors.email) {
      //     //   description = errors.email;
      //     // } else {
      //     //   description = errors.password;
      //     // }

      //     // addToast({
      //     //   type: "error",
      //     //   title: "Erro na autenticação",
      //     //   description,
      //     // });

      //     formRef.current?.setErrors(errors);

      //     return;
      //   }

      //   // addToast({
      //   //   type: "error",
      //   //   title: "Erro na autenticação",
      //   //   description: "Ocorreu um erro ao fazer login, cheque as credenciais.",
      //   // });
      // }
    },
    [history]
  );

  const handleLogin = useCallback(async () => {
    try {
      // await signIn();
    } catch (err) {
      return;
    }
  }, []);

  return (
    <Container>
      <CircularProgress />
      {/*
      <BackgroundBRF src={BackgroundImg} alt="BRF-Background" />
      <Section>
         <Form ref={formRef} onSubmit={handleSubmit}>
          <Logos>
            <LogoImg src={ProfitLogoImg} alt="Profit" />
            <LogoImg src={BRFLogoImg} alt="BRF" />
          </Logos>

          <Title>Faça o login com a sua conta de rede</Title>
          <Input name="email" placeholder="E-mail" />
          <Button
            type="submit"
            containerStyle={{ marginTop: "2rem", borderRadius: "10px" }}
          >
            Login
          </Button>
        </Form> 
        
        <Button
          type="button"
          containerStyle={{ marginTop: "2rem", borderRadius: "10px" }}
          onClick={handleLogin}
        >
          Login
        </Button>
      </Section> */}
    </Container>
  );
};

export default Login;
