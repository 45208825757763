import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { FaHome } from "react-icons/fa";

import { useParams } from "react-router-dom";
import HeaderContainer from "../../components/HeaderContainer";
import PageContainer from "../../components/PageContainer";
import PageTitle from "../../components/PageTitle";
import Paper from "../../components/Paper";
import { useToast } from "../../contexts/ToastContext";

const Dashboard: React.FC = () => {
  const { error } = useParams<{ error: string }>();
  const { addToast } = useToast();
  const errorMessage =
    localStorage.getItem("errorMessage") || "Ops! Ocorreu um erro.";

  useEffect(() => {
    if (error) {
      addToast({
        duration: 20000,
        type: "error",
        title: "Erro!",
        description: errorMessage,
      });
    }
  }, [addToast, error, errorMessage]);

  return (
    <PageContainer>
      <HeaderContainer>
        <PageTitle icon={<FaHome />} title="Seja bem-vindo" />
      </HeaderContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper containerStyle={{ height: "100%", minHeight: "70vh" }}></Paper>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Dashboard;
