import React from "react";
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";

interface RouteProps extends ReactDOMRouteProps {
  isPublic?: boolean;
  permission?: string;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPublic = false,
  permission,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  if (!isPublic && (!user || Object.keys(user).length === 0)) {
    return <Redirect to={{ pathname: "/login" }} />;
  }

  if (
    permission &&
    !/master/i.test(user.profile_type) &&
    !user.permissions.includes(permission)
  ) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return <ReactDOMRoute {...rest} render={() => <Component />} />;
};

export default Route;
