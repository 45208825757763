import { CssBaseline } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Switch, useLocation } from "react-router-dom";
import Route from "./Routes";

import AppBar from "../components/AppBar";

import Approval from "../pages/Approval";
import Authenticate from "../pages/Authenticate";
import Login from "../pages/Login";
import PriceBase from "../pages/PriceBase/";
import PriceBaseNewSceneryV2 from "../pages/PriceBase/NewSceneryV2";
import PriceBaseParameters from "../pages/PriceBase/Parameters";
import PriceBaseParametersTable from "../pages/PriceBase/Parameters/Table";
import PriceBaseUpload from "../pages/PriceBase/Parameters/Upload";
import PriceBaseRelativityV2 from "../pages/PriceBase/RelativityV2";
import PriceBaseResultV2 from "../pages/PriceBase/ResultV2";
import PriceManagement from "../pages/PriceManagement";
import Processed from "../pages/Processed/";
import ProcessedDetailsV2 from "../pages/Processed/DetailsV2";
import ProcessedNewSceneryV2 from "../pages/Processed/NewSceneryV2";
import ProcessedParameters from "../pages/Processed/Parameters";
import ProcessedParametersTable from "../pages/Processed/Parameters/Table";
import ProcessedUpload from "../pages/Processed/Parameters/Upload";
import ProcessedValidate from "../pages/Processed/Parameters/Validate";
import Reports from "../pages/Reports";
import UnauthorizedUser from "../pages/UnauthorizedUser";

import AccessControl from "../pages/AccessControl";
import Dashboard from "../pages/Dashboard";
import UserPage from "../pages/UserPage";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    zIndex: 0,
  },
}));

const Routes: React.FC = () => {
  const [loadAppBar, setLoadAppBar] = useState(false);

  const classes = useStyles();
  const { pathname } = useLocation();

  useEffect(() => {
    const [, routeName] = pathname.split("/");

    if (
      routeName !== "login" &&
      routeName !== "unauthorized-user" &&
      routeName !== "authenticate"
    ) {
      setLoadAppBar(true);
    } else {
      setLoadAppBar(false);
    }
  }, [pathname]);

  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      {loadAppBar && <AppBar />}

      <main className={classes.content}>
        {loadAppBar && <div className={classes.appBarSpacer} />}

        <Switch>
          <Route component={Login} path="/login" isPublic />
          <Route
            component={UnauthorizedUser}
            path="/unauthorized-user"
            isPublic
          />
          <Route
            component={Authenticate}
            path="/authenticate/:token"
            isPublic
          />
          <Route
            component={Dashboard}
            path={["/", "/dashboard", "/dashboard/:error"]}
            exact
          />

          <Route
            component={AccessControl}
            path="/access/list"
            permission="view_access_control"
          />
          <Route
            component={UserPage}
            path="/access/user/:userId"
            permission="view_access_control"
          />
          <Route
            component={PriceManagement}
            path="/price-management"
            permission="view_price_management"
          />
          <Route
            component={Approval}
            path="/approval"
            permission="view_approval"
          />
          <Route
            component={Reports}
            path="/reports"
            permission="view_reports"
          />
          <Route
            exact
            component={PriceBase}
            path="/price-base"
            permission="view_in_natura"
          />
          <Route
            exact
            component={PriceBaseNewSceneryV2}
            path="/price-base/new-scenery/:sceneryId/:type"
            permission="view_in_natura"
          />
          <Route
            component={PriceBaseUpload}
            path="/price-base/parameters/upload"
            permission="view_in_natura"
          />
          <Route
            component={PriceBaseResultV2}
            path="/price-base/result/:sceneryId"
            permission="view_in_natura"
          />
          <Route
            component={PriceBaseRelativityV2}
            path="/price-base/relativity/:sceneryId"
            permission="view_in_natura"
          />
          <Route
            exact
            component={PriceBaseParameters}
            path="/price-base/parameters"
            permission="view_in_natura"
          />
          <Route
            exact
            component={PriceBaseParametersTable}
            path="/price-base/parameters/:tableCoded"
            permission="view_in_natura"
          />
          <Route
            component={ProcessedUpload}
            path="/price-base/parameters/upload"
            permission="view_in_natura"
          />
          <Route
            exact
            component={Processed}
            path="/processed"
            permission="view_processed"
          />
          <Route
            exact
            component={ProcessedNewSceneryV2}
            path="/processed/scenery/:id"
            permission="view_processed"
          />
          <Route
            exact
            component={ProcessedDetailsV2}
            path="/processed/scenery/:id/details/"
            permission="view_processed"
          />
          <Route
            component={ProcessedUpload}
            path="/processed/parameters/upload"
            permission="view_processed"
          />
          <Route
            component={ProcessedValidate}
            path="/processed/parameters/validate"
            permission="view_processed"
          />
          <Route
            exact
            component={ProcessedParameters}
            path="/processed/parameters"
            permission="view_processed"
          />
          <Route
            exact
            component={ProcessedParametersTable}
            path="/processed/parameters/:tableCoded"
            permission="view_processed"
          />
        </Switch>
      </main>
    </div>
  );
};

export default Routes;
