import React, { useEffect } from "react";
import { useTransition } from "react-spring";

import { ToastMessage } from "../../contexts/ToastContext";
import { Container } from "./styles";
import Toast from "./Toast";

interface ToastContainerProps {
  messages: ToastMessage[];
  removeToast: (id: string) => void; // Recebe a função de remoção
}

const ToastContainer: React.FC<ToastContainerProps> = ({ messages, removeToast }) => {
  const messagesWithTransitions = useTransition(messages, {
    keys: (item) => item.id,
    from: { right: "-120%", opacity: 0.5 },
    enter: { right: "0%", opacity: 1 },
    leave: { right: "-120%", opacity: 0 },
  });

  // Remoção automática após a duração configurada
  useEffect(() => {
    const timers = messages.map((message) =>
      setTimeout(() => {
        removeToast(message.id);
      }, message.duration || 10000) 
    );

    return () => {
      timers.forEach((timer) => clearTimeout(timer)); 
    };
  }, [messages, removeToast]);

  return (
    <Container>
      {messagesWithTransitions((props, item) => (
        <Toast key={item.id} style={props} message={item} />
      ))}
    </Container>
  );
};

export default ToastContainer;
