export const endpoints: string[] = [
  "/material/category",
  "/material/list",
  "/price/list/group-client",
  "/material/hierarquia",
  "/price/list/distributionChannels",
];

interface IDefOptions {
  priceList: any[];
  company: any[];
  expCondition: any[];
}

export const defOptions: IDefOptions = {
  priceList: [],
  company: [
    // { key: 0, label: "Selecione", value: "" },
    { key: 1, label: "2500", value: "2500" },
    { key: 1, label: "4144", value: "4144" },
  ],
  expCondition: [
    { key: 0, label: "Selecione", value: "" },
    { key: 2, label: "DD", value: "DD" },
  ],
};
